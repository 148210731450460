<template>
  <div class="content">
    <div class="container-fluid">
        <div v-if="messageAlert">
          <Notification :message="messageAlert" @statusAlertMsg="cambiarMsgAlert" />
        </div>
        <div>
          <DxDataGrid
            id="gridContainer"
            :data-source="dataSource"
            key-expr="id"
            :column-hiding-enabled="true"
            :show-borders="true"
            @exporting="onExporting"
            @cell-prepared="onCellPrepared"
          >
            <DxHeaderFilter :visible="true"/>
            <DxFilterRow :visible="true"/>
            <DxGrouping :context-menu-enabled="true" expand-mode="rowClick" />
            <DxGroupPanel :visible="true" empty-panel-text="Use el menú contextual de las columnas de encabezado para agrupar datos" />
            <DxColumnChooser :enabled="true" mode="select" />
            <DxExport :enabled="true" :formats="['pdf', 'xlsx']"/>
            <DxColumn
              dataField="id"
              caption=""
              :showInColumnChooser="false"
              :allowFiltering="false"
              :allowSorting="false"
              cellTemplate="cellEditar"
              :fixed="true"
            /> 
            <DxColumn dataField="sitio" caption="Sitio" data-type="string"/>
            <DxColumn dataField="propietario[0].nombre" caption="Propietario" data-type="string"/>
            <DxColumn
              dataField="estatus"
              caption="Estatus"
              data-type="string"
            >
              <DxLookup
                  :data-source="lookupData"
              />
            </DxColumn>
            <DxColumn dataField="contacto" caption="Contacto" data-type="string"/>
            <DxColumn dataField="email" caption="Correo electrónico" data-type="string"/>
            <DxColumn dataField="telefono" caption="Teléfono" />
            <DxColumn dataField="whatsapp" caption="Whatsapp" />
            <DxColumn dataField="pais" caption="País" data-type="string"/>
            <DxColumn dataField="especializado" caption="Especializado" data-type="string"/>
            <DxColumn dataField="tematicas_prohibidas" caption="Temáticas prohibidas" data-type="string"/>
            <DxColumn dataField="datos_de_pago" caption="Datos de pago" data-type="string"/>
            <DxColumn
              dataField="divisa"
              caption="Divisa"
              data-type="string"
            />
            <DxColumn
              dataField="costo_1"
              caption="Costo uno"
              data-type="number"
              format="currency" 
            />
            <DxColumn
              dataField="costo_2"
              caption="Costo dos"
              data-type="number"
              format="currency" 
            />
            <DxColumn
              dataField="costo_3"
              caption="Costo tres"
              data-type="number"
              format="currency" 
            />
            <DxColumn
              dataField="precio_1"
              caption="Precio uno"
              data-type="number"
              format="currency" 
            />
            <DxColumn
              dataField="precio_2"
              caption="Precio dos"
              data-type="number"
              format="currency"
            />
            <DxColumn
              dataField="precio_3"
              caption="Precio tres"
              data-type="number"
              format="currency"
            />
            <DxColumn dataField="notas" caption="Notas" data-type="string"/>
            <DxToolbar>
              <DxItem location="before" name="groupPanel" />
              <DxItem location="after" template="refreshTemplate" />
              <DxItem location="after" name="exportButton" />
              <DxItem location="after" name="columnChooserButton" />
            </DxToolbar>
            <template #refreshTemplate>
              <DxButton icon="plus" @click="agregarWebsite()"/>
            </template> 
            <DxPager
              :allowed-page-sizes="[5, 8, 15, 30]"
              :show-info="true"
              :show-navigation-buttons="true"
              :show-page-size-selector="true"
              :visible="true"
            />
            <template #cellEditar="{ data }"> 
              <div class="text-center">
                <a href="#" class="text-decoration-none me-2 fas fa-pen fa-sm" @click="abrirModalEditar( data )" title="Editar"></a>
                <a href="#" :class="data.data.estatus=='Inactivo'?'me-2 fas fa-lock fa-sm':'me-2 fas fa-unlock fa-sm'" @click="abrirModalStatusFila(data)" :title="data.data.estatus=='Inactivo'?'Activar':'Desactivar'" style="text-decoration:none;"></a>
                <a href="#" class="text-decoration-none fas fa-eye fa-sm" @click="abrirModalShow( data )" title="Mostrar"></a>
              </div>
            </template>
            <DxPaging :page-size="8"/>
          </DxDataGrid>
        </div>
        <dx-popup
          :height="'auto'"
          :showTitle="true"
          titleTemplate="titleModal"
          :dragEnabled="false"
          :hideOnOutsideClick="true"
          :visible="popupVisibleCreate"
          @hidden="onHidden"
        >
          <template #titleModal>
            <h3>{{titleModal}}</h3>
          </template>
          <div>
            <form action="">
              <div class="form">
                <div class="dx-fieldset">
                  <div class="row">
                    <div class="col-md-6">
                        <DxTextBox label="Sitio:*" v-model="website.sitio" />
                        <small class="error_message" v-for="(e_sitio, index) in errors.sitio" :key="index">{{e_sitio}}</small>
                    </div>
                    <div class="col-md-6">
                        <DxTextBox label="Contacto:" v-model="website.contacto" />
                        <small class="error_message" v-for="(e_contacto, index) in errors.contacto" :key="index">{{e_contacto}}</small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                        <DxTextBox
                          label="Teléfono:"
                          v-model="website.telefono"
                        />
                        <small class="error_message" v-for="(e_telefono, index) in errors.telefono" :key="index">{{e_telefono}}</small>
                    </div>
                    <div class="col-md-3">
                        <DxTextBox
                          label="Whatsapp:"
                          v-model="website.whatsapp"
                        />
                        <small class="error_message" v-for="(e_whatsapp, index) in errors.whatsapp" :key="index">{{e_whatsapp}}</small>
                    </div>
                    <div class="col-md-3">
                        <DxTextBox label="Correo electrónico:" v-model="website.email" />
                        <small class="error_message" v-for="(e_email, index) in errors.email" :key="index">{{e_email}}</small>
                    </div>
                    <div class="col-md-3">
                        <DxTextBox label="País:" v-model="website.pais" />
                        <small class="error_message" v-for="(e_pais, index) in errors.pais" :key="index">{{e_pais}}</small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12">
                          <DxTextArea
                            label="Datos de pago:"
                            :height="140"
                            :max-length="maxLength"
                            v-model="website.datos_de_pago"
                          />
                          <small class="error_message" v-for="(e_datos_de_pago, index) in errors.datos_de_pago" :key="index">{{e_datos_de_pago}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-12">
                          <DxTextBox label="Especializado:" v-model="website.especializado" />
                          <small class="error_message" v-for="(e_especializado, index) in errors.especializado" :key="index">{{e_especializado}}</small>
                        </div>
                      </div> 
                      <div class="row">
                        <div class="col-md-12">
                            <DxTextBox label="Temáticas prohibidas:" v-model="website.tematicas_prohibidas" />
                            <small class="error_message" v-for="(e_tematicas_prohibidas, index) in errors.tematicas_prohibidas" :key="index">{{e_tematicas_prohibidas}}</small>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">                            
                          <DxSelectBox
                            label="Propietario:*"
                            :search-enabled="true"
                            :data-source="propietarios"
                            :search-mode="searchModeOption"
                            :search-expr="searchExprOptionPropietario"
                            :search-timeout="searchTimeoutOption"
                            :min-search-length="minSearchLengthOption"
                            :show-data-before-search="showDataBeforeSearchOption"
                            display-expr="nombre"
                            value-expr="id"
                            v-model="website.propietario_id"
                          />
                          <small class="error_message" v-for="(e_propietario_id, index) in errors.propietario_id" :key="index">{{e_propietario_id}}</small>                            
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                        <DxNumberBox label="Costo uno:*" v-model="website.costo_1" format="$ #,##0.##"/>
                        <small class="error_message" v-for="(e_costo_1, index) in errors.costo_1" :key="index">{{e_costo_1}}</small>
                    </div>
                    <div class="col-md-3">
                        <DxNumberBox label="Costo dos:*" v-model="website.costo_2" format="$ #,##0.##"/>
                        <small class="error_message" v-for="(e_costo_2, index) in errors.costo_2" :key="index">{{e_costo_2}}</small>
                    </div>
                    <div class="col-md-3">
                        <DxNumberBox label="Costo tres:*" v-model="website.costo_3" format="$ #,##0.##"/>
                        <small class="error_message" v-for="(e_costo_3, index) in errors.costo_3" :key="index">{{e_costo_3}}</small>
                    </div>
                    <div class="col-md-3">
                      <dx-select-box
                          label="Divisa costo:*"
                          :search-enabled="true"
                          :data-source="divisas"
                          :search-mode="searchModeOption"
                          :search-expr="searchExprOptionDivisaCosto"
                          :search-timeout="searchTimeoutOption"
                          :min-search-length="minSearchLengthOption"
                          :show-data-before-search="showDataBeforeSearchOption"
                          display-expr="descripcion"
                          value-expr="descripcion"
                          v-model="website.divisa_costo"
                      ></dx-select-box>
                      <small class="error_message" v-for="(e_divisa_costo, index) in errors.divisa_costo" :key="index">{{e_divisa_costo}}</small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                        <DxNumberBox label="Precio uno:*" v-model="website.precio_1" format="$ #,##0.##"/>
                        <small class="error_message" v-for="(e_precio_1, index) in errors.precio_1" :key="index">{{e_precio_1}}</small>
                    </div>
                    <div class="col-md-3">
                        <DxNumberBox label="Precio dos:*" v-model="website.precio_2" format="$ #,##0.##"/>
                        <small class="error_message" v-for="(e_precio_2, index) in errors.precio_2" :key="index">{{e_precio_2}}</small>
                    </div>
                    <div class="col-md-3">
                        <DxNumberBox label="Precio tres:*" v-model="website.precio_3" format="$ #,##0.##"/>
                        <small class="error_message" v-for="(e_precio_3, index) in errors.precio_3" :key="index">{{e_precio_3}}</small>
                    </div>
                    <div class="col-md-3">
                      <dx-select-box
                          label="Divisa precio:*"
                          :search-enabled="true"
                          :data-source="divisas"
                          :search-mode="searchModeOption"
                          :search-expr="searchExprOptionDivisaPrecio"
                          :search-timeout="searchTimeoutOption"
                          :min-search-length="minSearchLengthOption"
                          :show-data-before-search="showDataBeforeSearchOption"
                          display-expr="descripcion"
                          value-expr="descripcion"
                          v-model="website.divisa_precio"
                      ></dx-select-box>
                      <small class="error_message" v-for="(e_divisa_precio, index) in errors.divisa_precio" :key="index">{{e_divisa_precio}}</small>
                    </div>
                  </div>
                  <div class="row">  
                    <div class="col-md-12">
                      <DxTextArea
                        label="Notas:"
                        :height="80"
                        :max-length="maxLength"
                        v-model="website.notas"
                      />
                      <small class="error_message" v-for="(e_notas, index) in errors.notas" :key="index">{{e_notas}}</small>
                    </div>
                    <div class="col-md-6">
                      <div  v-if="option=='edit'" class="dx-field" >
                        <div class="dx-field-label">Estatus:</div>
                        <div class="dx-field-value">
                          <div v-if="website.status==1" class="dx-field-label">Activo</div>
                          <a v-if="website.status==0" class="btn-custom" style="background: #FF0000 0% 0% no-repeat padding-box">
                            Inactivo
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <span>* Datos requeridos</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <dx-button class="me-2" type="normal" text="Cancelar" @click="cancelarEditar()"></dx-button>
                      <dx-button class="me-2" v-if="option=='save'" icon="save" type="default" text="Guardar" @click="nuevoWebsite()"></dx-button>
                      <dx-button class="me-2" v-if="option=='edit' && website.status==1" icon="clear" type="danger" text="Inactivar" @click="abrirModalStatusEditar()"></dx-button>
                      <dx-button class="me-2" v-if="option=='edit' && website.status==0" icon="fas fa-unlock fa-sm" type="default" text="Activar" @click="abrirModalStatusEditar()"></dx-button>
                      <dx-button class="me-2" v-if="option=='edit'" icon="fas fa-lock fa-sm" type="default" text="Guardar" @click="actualizarWebsite()"></dx-button>
                    </div>
                  </div>
                </div>
              </div>
            </form>            
          </div>
        </dx-popup>
        <dx-popup
          :width="'auto'"
          :height="'auto'"
          :showTitle="true"
          titleTemplate="titleModal"
          :dragEnabled="false"
          :hideOnOutsideClick="true"
          :visible="popupVisibleStatus"
          @hidden="onHiddenStatus"
        >
          <template #titleModal>
            <h3>{{titleStatus}} website</h3>
          </template>
          <div>
            <div class="row">
              <div class="col-md-12">
                <h3>¿Deseas {{titleStatus.toLowerCase()}} al website "{{website_sitio}}"?</h3>
                <br>
              </div>
            </div>
            <div class="row">
              <div class="modal-footer">
                <dx-button
                  class="me-2"
                  type="normal"
                  text="Cancelar"
                 @click="cancelarStatus()"
                ></dx-button>
                <dx-button
                  type="danger"
                  :text="website_status==1?'Desactivar':'Activar'"
                  @click="cambiarStatus()"
                ></dx-button>
              </div>
            </div>
          </div>
        </dx-popup>
        <dx-popup
          :width="'auto'"
          :height="'auto'"
          :showTitle="true"
          titleTemplate="titleModal"
          :dragEnabled="false"
          :hideOnOutsideClick="true"
          :visible="popupVisibleShow"
          @hidden="onHiddenShow"
        >
          <template #titleModal>
            <h3>Información Wbsite</h3>
          </template>
          <div>
            <div class="row">
              <div class="col-md-12">
                <div class="row mb-2">
                  <div class="col-md-6">
                    <span>Sitio: {{website.sitio}}</span>
                  </div>
                  <div class="col-md-6">
                    <span>Contacto: {{website.contacto}}</span>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-3">                      
                    <span>Teléfono: {{website.telefono}}</span>
                  </div>
                  <div class="col-md-3">
                    <span>Whatsapp: {{website.whatsapp}}</span>
                  </div>
                  <div class="col-md-3">
                    <span>Correo electrónico: {{website.email}}</span>  
                  </div>
                  <div class="col-md-3">  
                    <span>País: {{website.pais}}</span>  
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-12">                        
                        <span>Datos de pago: {{website.datos_de_pago}}</span>                          
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="row mb-2">
                      <div class="col-md-12">
                        <span>Especializado: {{website.especializado}}</span>                          
                      </div>
                    </div> 
                    <div class="row mb-2">
                      <div class="col-md-12">
                          <span>Temáticas prohibidas: {{website.tematicas_prohibidas}}</span>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-md-12">
                        <span>Propietario: {{website.propietario_id}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-3">
                    <span>Costo uno: {{website.costo_1}}</span>
                  </div>
                  <div class="col-md-3">
                    <span>Costo dos: {{website.costo_2}}</span>  
                  </div>
                  <div class="col-md-3">
                    <span>Costo tres: {{website.costo_3}}</span>
                  </div>
                  <div class="col-md-3">
                    <span>Divisa costo: {{website.divisa_costo}}</span>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-3">
                    <span>Precio uno: {{website.precio_1}}</span>
                  </div>
                  <div class="col-md-3">
                    <span>Precio dos: {{website.precio_2}}</span>
                  </div>
                  <div class="col-md-3">
                    <span>Precio tres: {{website.precio_3}}</span>
                  </div>
                  <div class="col-md-3">
                    <span>Divisa precio: {{website.divisa_precio}}</span>
                  </div>
                </div>
                <div class="row mb-2">  
                  <div class="col-md-12">
                    <span>Notas: {{website.notas}}</span>
                  </div>
                  <div class="col-md-6">
                    <div  v-if="option=='edit'" class="dx-field" >
                      <div class="dx-field-label">Estatus:</div>
                      <div class="dx-field-value">
                        <div v-if="website.status==1" class="dx-field-label">Activo</div>
                        <a v-if="website.status==0" class="btn-custom" style="background: #FF0000 0% 0% no-repeat padding-box">
                          Inactivo
                        </a>
                      </div>
                    </div>
                  </div>
                </div>  
                <div class="row">
                  <div class="col-md-12 d-flex justify-content-end">
                    <dx-button class="me-2" type="normal" text="Aceptar" @click="cancelarShow()"></dx-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </dx-popup>
    </div>
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxScrolling,
  DxPager,
  DxExport,
  DxHeaderFilter,
  DxFilterRow,
  DxColumnFixing,
  DxColumnChooser,
  DxSelection,
  DxStateStoring,
  DxGroupPanel,
  DxToolbar,
  DxEditing,
  DxGrouping,
} from 'devextreme-vue/data-grid';
import {
    DxValidator,
    DxRequiredRule,
    DxCustomRule
} from 'devextreme-vue/validator';
 
import { DxTextArea } from 'devextreme-vue/text-area';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxItem, DxSimpleItem } from 'devextreme-vue/form';
import { DxPopup } from 'devextreme-vue/popup';
import { DxButton } from 'devextreme-vue/button';
import { DxCheckBox } from 'devextreme-vue';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { DxNumberBox } from 'devextreme-vue/number-box';
import { jsPDF } from 'jspdf';
import { exportDataGrid as exportDataGridToPdf} from 'devextreme/pdf_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import axios from "axios";
import Notification from "./NotificationComponent";
import 'devextreme/dist/css/dx.light.css';
import "bootstrap/dist/css/bootstrap.min.css";

const lookupData = [
  'Activo',
  'Inactivo',
];

const divisas = [{
    descripcion: 'USD',
  },
  {
    descripcion: 'EUR',
  },
  {
    descripcion: 'MXN',
  }, 
]


export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxLookup,
        DxForm,
        DxItem,
        DxTextArea,
        DxScrolling,
        DxPager,
        DxExport,
        DxHeaderFilter,
        DxFilterRow,
        DxColumnFixing,
        DxColumnChooser,
        DxSelection,
        DxStateStoring,
        DxGroupPanel,
        DxPopup,
        DxButton,
        DxTextBox,
        DxNumberBox,
        DxToolbar,
        DxValidator,
        DxRequiredRule,
        DxCustomRule,
        DxCheckBox,
        DxSelectBox,
        DxLoadPanel,
        Notification,
        DxEditing,
        DxGrouping,
        DxSimpleItem
  },
  data() {
    return {
        searchExprOptionPropietario:"nombre",
        divisas,
        lookupData,
        maxLength: null,
        labelMode: 'floating',
        labelLocation: 'left',
        dataSource:[],
        propietarios:[],
        displayModes: [{ text: 'Display Mode \'full\'', value: 'full' }, { text: 'Display Mode \'compact\'', value: 'compact' }],
        displayMode: 'full',
        pageSizes: [5, 10, 'all'],
        showPageSizeSelector: true,
        showInfo: true,
        showNavButtons: true,
        errors:[],
        website_id:"",
        website_sitio:"",
        website_status:1,
        titleStatus:"Activar",
        titleModal:"",
        option:"save",
        website:{
          id:"",
          sitio:"",
          contacto:"",
          propietario_id:"",
          datos_de_pago:"",
          email:"",
          telefono:"",
          whatsapp:"",
          pais:"",
          especializado:"",
          tematicas_prohibidas:"",
          divisa_costo:"",
          divisa_precio:"",
          costo_1:"",
          costo_2:"",
          costo_3:"",
          precio_1:"",
          precio_2:"",
          precio_3:"",
          notas:"",
          status:"",
        },
        popupVisibleCreate: false,
        popupVisibleDelete:false,
        popupVisibleStatus:false,
        popupVisibleShow:false,
        titleStatus:"Activar",
        searchModeOption: 'contains',
        searchExprOption: 'name',
        searchExprOptionDivisaCosto: 'descripcion',
        searchExprOptionDivisaPrecio: 'descripcion',
        searchTimeoutOption: 200,
        minSearchLengthOption: 0,
        showDataBeforeSearchOption: false,
        isLoadPanelVisible: false,
        messageAlert:0,
    }
  },
  methods: {
    abrirModalShow(value){
      this.popupVisibleShow=true;
      this.website_sitio=value.data.sitio;
      this.website_status = value.data.status;
      this.website.id = value.value;
      this.website.sitio=value.data.sitio;
      this.website.contacto=value.data.contacto;
      this.website.propietario_id = value.data.propietario[0].nombre;
      this.website.datos_de_pago=value.data.datos_de_pago;
      this.website.email=value.data.email;
      this.website.telefono=value.data.telefono;
      this.website.whatsapp=value.data.whatsapp;
      this.website.pais=value.data.pais;
      this.website.especializado=value.data.especializado;
      this.website.tematicas_prohibidas=value.data.tematicas_prohibidas;
      this.website.divisa_costo=value.data.divisa_costo;
      this.website.divisa_precio=value.data.divisa_precio;
      this.website.costo_1=value.data.costo_1;
      this.website.costo_2=value.data.costo_2;
      this.website.costo_3=value.data.costo_3;
      this.website.precio_1=value.data.precio_1;
      this.website.precio_2=value.data.precio_2;
      this.website.precio_3=value.data.precio_3;
      this.website.status = value.data.status;
      this.website.notas = value.data.notas;
    },
    onCellPrepared(e){
        if(e.rowType === "data" && e.column.dataField === "estatus" && e.data.status==0) {
          e.cellElement.style.backgroundColor =  "#FF0000"; 
          e.cellElement.style.color =  "white"; 
          e.cellElement.style.borderRadius =  "15px";
          e.cellElement.style.textAlign = "center"
        }
    },
    onExporting(e) {
        if (e.format === 'xlsx') {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('Main sheet');
            exportDataGrid({
                component: e.component,
                worksheet: worksheet,
                customizeCell: function(options) {
                    const excelCell = options;
                    excelCell.font = { name: 'Arial', size: 12 };
                    excelCell.alignment = { horizontal: 'left' };
                } 
            }).then(function() {
                workbook.xlsx.writeBuffer()
                    .then(function(buffer) {
                        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'websites.xlsx');
                    });
            });
            e.cancel = true;
        } 
        else if (e.format === 'pdf') {
            const doc = new jsPDF('l', 'mm', [297, 210]);
            exportDataGridToPdf({
                jsPDFDocument: doc,
                component: e.component
            }).then(() => {
                doc.save('websites.pdf');
            })
        }
    },
    cambiarMsgAlert(value){
      this.messageAlert= value;
    },  
    cleanTextBox(){
      this.website.id="";
      this.website.sitio="";
      this.website.contacto="";
      this.website.propietario_id="";
      this.website.datos_de_pago="";
      this.website.email="";
      this.website.telefono="";
      this.website.whatsapp="";
      this.website.pais="";
      this.website.especializado="";
      this.website.tematicas_prohibidas="";
      this.website.divisa_costo="";
      this.website.divisa_precio="";
      this.website.costo_1="";
      this.website.costo_2="";
      this.website.costo_3="";
      this.website.precio_1="";
      this.website.precio_2="";
      this.website.precio_3="";
      this.website.notas="";
      this.website.status="";
    },
    agregarWebsite(){
      this.cleanTextBox();
      this.showChildModal();
      this.titleModal="Nuevo website"
      this.option="save";
    },
    showChildModal(){
      this.popupVisibleCreate =  true;
    },
    nuevoWebsite(){
      axios.post('websites', this.website).then(response => {
        if(response && response.data.statusCode == 200){
          this.messageAlert = response.data.message;
          this.cleanTextBox();
          this.hideChildModal();
          this.getList();
				}
				else{
					console.log(response);
				}
			}).catch((error)=> {
				if (error.response.status == 422){
					this.errors = error.response.data.errors;
				}else{
					console.log(this.errors);
				}
			}); 
    },
    hideChildModal(){
      this.popupVisibleCreate =  false;
    },
    abrirModalEditar(value){
      console.log(value);
      this.titleModal="Editar website"
      this.option="edit";
      this.cleanTextBox();
      this.showChildModal();
      this.website_id = value.value;
      this.website_sitio=value.data.sitio;
      this.website_status = value.data.status;
      this.website.id = value.value;
      this.website.sitio=value.data.sitio;
      this.website.contacto=value.data.contacto;
      this.website.propietario_id = value.data.propietario_id;
      this.website.datos_de_pago=value.data.datos_de_pago;
      this.website.email=value.data.email;
      this.website.telefono=value.data.telefono;
      this.website.whatsapp=value.data.whatsapp;
      this.website.pais=value.data.pais;
      this.website.especializado=value.data.especializado;
      this.website.tematicas_prohibidas=value.data.tematicas_prohibidas;
      this.website.divisa_costo=value.data.divisa_costo;
      this.website.divisa_precio=value.data.divisa_precio;
      this.website.costo_1=value.data.costo_1_org;
      this.website.costo_2=value.data.costo_2_org;
      this.website.costo_3=value.data.costo_3_org;
      this.website.precio_1=value.data.precio_1_org;
      this.website.precio_2=value.data.precio_2_org;
      this.website.precio_3=value.data.precio_3_org;
      this.website.status = value.data.status;
      this.website.notas = value.data.notas;
    },
     cancelarEditar(){
      this.cleanTextBox();
      this.hideChildModal();
      this.errors=[];
    },
    onHidden(e){
      this.cancelarEditar();
    },
    onHiddenShow(e){
      this.cancelarShow();
    },
    abrirModalStatusFila(value){
      this.popupVisibleStatus=true;
      this.website_id = value.value;
      this.website_sitio = value.data.sitio;
      this.website_status = value.data.status;
      this.titleStatus =  value.data.status==1?'Desactivar':'Activar';  
    },
    abrirModalStatusEditar(){
      this.popupVisibleCreate =  false;
      this.popupVisibleStatus=true;
      this.website_id = this.website.id;
      this.website_sitio = this.website.sitio;
      this.website_status = this.website.status;
      this.titleStatus =  this.website.status==1?'Desactivar':'Activar';
    },
    cambiarStatus(){
      axios.post('banned-website/'+this.website_id).then(response=>{
        if(response && response.data.statusCode == 200){
          this.messageAlert = response.data.message;
          this.cleanTextBox();
          this.popupVisibleStatus=false;
          this.getList();
        }
        else{
					console.log(response);
				}

      }).catch((error)=> {
				if (error.response.status == 422){
					this.errors = error.response.data.errors;
				}else{
					console.log(this.errors);
				}
			}); 
    },
    cancelarStatus(){
      this.popupVisibleStatus=false;
      this.website_id = "";
      this.website_sitio = "";
    },
    cancelarShow(){
      this.popupVisibleShow=false;
      this.cleanTextBox();
    },
    actualizarWebsite(){
      axios.put('websites/'+this.website_id, this.website).then(response => {
        if(response && response.data.statusCode == 200){
          this.messageAlert = response.data.message;
          this.cleanTextBox();
          this.hideChildModal();
          this.getList();
				}
				else{
					console.log(response);
				}
			}).catch((error)=> {
				if (error.response.status == 422){
					this.errors = error.response.data.errors;
				}else{
					console.log(this.errors);
				}
			}); 
    },
    onHiddenStatus(e){
      this.cancelarStatus();
    },
    getList(){
      const options = { style: 'currency', currency: 'MXN' };
      const numberFormat = new Intl.NumberFormat('es-MX', options);
	    axios.get('listar-websites').then(response=>{
        let websites = response.data.websites;
        websites.map(function(dato){
          dato.costo_1 = numberFormat.format( dato.costo_1);
          dato.costo_2 = numberFormat.format( dato.costo_2);
          dato.costo_3 = numberFormat.format( dato.costo_3);
          dato.precio_1 = numberFormat.format( dato.precio_1);
          dato.precio_2 = numberFormat.format( dato.precio_2);
          dato.precio_3 = numberFormat.format( dato.precio_3);
        });
        this.dataSource = websites;
        this.propietarios = response.data.propietarios;
      })
    },
  },
  mounted(){
    this.getList();
  },
}
</script>
<style scoped>
  .small {
    font-size: smaller;
  }
  .error_message{
    color:#FF6868;
    font-weight:normal !important;
  }
  .active_password{
      font-size: smaller;
      color: #33FFA9;
  }
  .desactive_password{
    font-size: smaller;
    color: #C4C4C4;
    transition: all 0.3s;
  }
  .btn-custom {
    display: inline-block;
    color: #fff;
    letter-spacing: 0;
    border-radius: 15px;
    padding: 2px 18px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    text-decoration: none;
  }
</style>
