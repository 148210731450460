<template>
  <div class="content">
    <div class="container-fluid">
        <div v-if="messageAlert">
          <Notification :message="messageAlert" @statusAlertMsg="cambiarMsgAlert" />
        </div>
        <div>
          <DxDataGrid
            id="gridContainer"
            :data-source="dataSource"
            key-expr="id"
            :column-hiding-enabled="true"
            :show-borders="true"
             @exporting="onExporting"
          >
            <DxHeaderFilter :visible="true"/>
            <DxFilterRow :visible="true"/>
            <DxGrouping :context-menu-enabled="true" expand-mode="rowClick" />
            <DxGroupPanel :visible="true" empty-panel-text="Use el menú contextual de las columnas de encabezado para agrupar datos" />
            <DxColumnChooser :enabled="true" mode="select" />
            <DxExport :enabled="true" :formats="['pdf', 'xlsx']"/>
            <DxColumn
              dataField="id"
              caption=""
              :showInColumnChooser="false"
              :allowFiltering="false"
              :allowSorting="false"
              cellTemplate="cellEditar"
              :fixed="true"
              width= '10%'
            /> 
            <DxColumn dataField="empresa" caption="Empresa" />
            <DxColumn dataField="contacto" caption="Contacto" />
            <DxColumn dataField="email" caption="Correo electrónico" />
            <DxColumn dataField="telefono" caption="Teléfono" />
            <DxColumn dataField="whatsapp" caption="Whatsapp" />
            <DxColumn dataField="estatus" caption="Estatus"  width= '10%'/>
            <DxColumn dataField="notas" caption="Notas" />
            <DxToolbar>
              <DxItem location="before" name="groupPanel" />
              <DxItem location="after" template="refreshTemplate" />
              <DxItem location="after" name="exportButton" />
              <DxItem location="after" name="columnChooserButton" />
            </DxToolbar>
            <template #refreshTemplate>
              <DxButton icon="plus" @click="agregarCliente()"/>
            </template> 
            <DxPager
              :allowed-page-sizes="[5, 8, 15, 30]"
              :show-info="true"
              :show-navigation-buttons="true"
              :show-page-size-selector="true"
              :visible="true"
            />
            <template #cellEditar="{ data }"> 
              <div class="text-center">
                <a href="#" class="text-decoration-none me-2 fas fa-pen fa-sm" @click="abrirModalEditar( data )" title="Editar"></a>
                <a href="#" :class="data.data.status==0?'me-2 fas fa-lock fa-sm':'me-2 fas fa-unlock fa-sm'" @click="abrirModalStatusFila(data)" :title="data.data.status==0?'Activar':'Desactivar'" style="text-decoration:none;"></a>
              </div>
            </template>
            <DxPaging :page-size="8"/>
          </DxDataGrid>
        </div>
        <dx-popup
          :height="'auto'"
          :showTitle="true"
          titleTemplate="titleModal"
          :dragEnabled="false"
          :hideOnOutsideClick="true"
          :visible="popupVisibleCreate"
          @hidden="onHidden"
        >
          <template #titleModal>
            <h3>{{titleModal}}</h3>
          </template>
          <div>
            <form action="">
              <div class="form">
                <div class="dx-fieldset">
                  <div class="row mb-2">
                    <div class="col-md-6">
                      <dx-text-box label="Empresa:" v-model="cliente.empresa"></dx-text-box>
                      <small class="error_message" v-for="(e_empresa, index) in errors.empresa" :key="index">{{e_empresa}}</small>
                    </div>
                    <div class="col-md-6">
                      <dx-text-box label="Contacto:" v-model="cliente.contacto"></dx-text-box>
                      <small class="error_message" v-for="(e_contacto, index) in errors.contacto" :key="index">{{e_contacto}}</small>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-6">  
                      <dx-text-box label="Correo electrónico:" v-model="cliente.email"></dx-text-box>
                      <small class="error_message" v-for="(e_email, index) in errors.email" :key="index">{{e_email}}</small>
                    </div>
                    <div class="col-md-6">
                      <dx-text-box label="Teléfono:" v-model="cliente.telefono"></dx-text-box>
                      <small class="error_message" v-for="(e_telefono, index) in errors.telefono" :key="index">{{e_telefono}}</small>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-6">
                      <dx-text-box label="Whatsapp:" v-model="cliente.whatsapp"></dx-text-box>
                      <small class="error_message" v-for="(e_whatsapp, index) in errors.whatsapp" :key="index">{{e_whatsapp}}</small>
                    </div>
                    <div class="col-md-6">
                      <dx-text-box label="Forma de pago:" v-model="cliente.forma_de_pago"></dx-text-box>
                      <small class="error_message" v-for="(e_forma_de_pago, index) in errors.forma_de_pago" :key="index">{{e_forma_de_pago}}</small>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-6">
                      <div  v-if="option=='edit'" class="dx-field" >
                        <div class="dx-field-label">Estatus:</div>
                        <div class="dx-field-value">
                          <div v-if="cliente.status==1" class="dx-field-label">Activo</div>
                          <div v-if="cliente.status==0" class="dx-field-label">Inactivo</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-12">
                      <DxTextArea label="Notas:" :height="90" v-model="cliente.notas" :max-length="maxLength"/>
                      <small class="error_message" v-for="(e_notas, index) in errors.notas" :key="index">{{e_notas}}</small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <dx-button class="me-2" type="normal" text="Cancelar" @click="cancelarEditar()"></dx-button>
                      <dx-button class="me-2" v-if="option=='save'" icon="save" type="default" text="Guardar" @click="nuevoCliente()"></dx-button>
                      <dx-button class="me-2" v-if="option=='edit' && cliente.status==1" icon="clear" type="danger" text="Inactivar" @click="abrirModalStatusEditar()"></dx-button>
                      <dx-button class="me-2" v-if="option=='edit' && cliente.status==0" icon="fas fa-unlock fa-sm" type="default" text="Activar" @click="abrirModalStatusEditar()"></dx-button>
                      <dx-button class="me-2" v-if="option=='edit'" icon="fas fa-lock fa-sm" type="default" text="Guardar" @click="actualizarCliente()"></dx-button>
                    </div>
                  </div>
                </div>
              </div>
            </form>            
          </div>
        </dx-popup>
        <dx-popup
          :width="'auto'"
          :height="'auto'"
          :showTitle="true"
          titleTemplate="titleModal"
          :dragEnabled="false"
          :hideOnOutsideClick="true"
          :visible="popupVisibleDelete"
          @hidden="onHiddenDelete"
        >
          <template #titleModal>
            <h3>Eliminar cliente</h3>
          </template>
          <div>
            <div class="row">
              <div class="col-md-12">
                <h3>¿Deseas eliminar al cliente "{{cliente_empresa}}"?</h3>
                <br>
              </div>
            </div>
            <div class="row">
              <div class="modal-footer">
                <dx-button
                  class="me-2"
                  type="normal"
                  text="Cancelar"
                 @click="cancelarEliminar()"
                ></dx-button>
                <dx-button
                  icon="trash"
                  type="danger"
                  text="Eliminar"
                  @click="eliminar()"
                ></dx-button>
              </div>
            </div>
          </div>
        </dx-popup>
        <dx-popup
          :width="'auto'"
          :height="'auto'"
          :showTitle="true"
          titleTemplate="titleModal"
          :dragEnabled="false"
          :hideOnOutsideClick="true"
          :visible="popupVisibleStatus"
          @hidden="onHiddenStatus"
        >
          <template #titleModal>
            <h3>{{titleStatus}} cliente</h3>
          </template>
          <div>
            <div class="row">
              <div class="col-md-12">
                <h3>¿Deseas {{titleStatus.toLowerCase()}} al cliente "{{cliente_empresa}}"?</h3>
                <br>
              </div>
            </div>
            <div class="row">
              <div class="modal-footer">
                <dx-button
                  class="me-2"
                  type="normal"
                  text="Cancelar"
                 @click="cancelarStatus()"
                ></dx-button>
                <dx-button
                  type="danger"
                  :text="cliente_status==1?'Desactivar':'Activar'"
                  @click="cambiarStatus()"
                ></dx-button>
              </div>
            </div>
          </div>
        </dx-popup>
    </div>
  </div>
</template>
<script>

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxScrolling,
  DxPager,
  DxExport,
  DxHeaderFilter,
  DxFilterRow,
  DxColumnFixing,
  DxColumnChooser,
  DxSelection,
  DxStateStoring,
  DxGroupPanel,
  DxToolbar,
  DxEditing,
  DxGrouping,
} from 'devextreme-vue/data-grid';
import {
    DxValidator,
    DxRequiredRule,
    DxCustomRule
} from 'devextreme-vue/validator';
 
import { DxTextArea } from 'devextreme-vue/text-area';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxItem } from 'devextreme-vue/form';
import { DxPopup } from 'devextreme-vue/popup';
import { DxButton } from 'devextreme-vue/button';
import { DxCheckBox } from 'devextreme-vue';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { jsPDF } from 'jspdf';
import { exportDataGrid as exportDataGridToPdf} from 'devextreme/pdf_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';

import axios from "axios";
import Notification from "./NotificationComponent";
import 'devextreme/dist/css/dx.light.css';
import "bootstrap/dist/css/bootstrap.min.css";

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxLookup,
        DxForm,
        DxItem,
        DxTextArea,
        DxScrolling,
        DxPager,
        DxExport,
        DxHeaderFilter,
        DxFilterRow,
        DxColumnFixing,
        DxColumnChooser,
        DxSelection,
        DxStateStoring,
        DxGroupPanel,
        DxPopup,
        DxButton,
        DxTextBox,
        DxToolbar,
        DxValidator,
        DxRequiredRule,
        DxCustomRule,
        DxCheckBox,
        DxSelectBox,
        DxLoadPanel,
        Notification,
        DxEditing,
        DxGrouping,
  },
  data() {
    return {
        dataSource:[],
        displayModes: [{ text: 'Display Mode \'full\'', value: 'full' }, { text: 'Display Mode \'compact\'', value: 'compact' }],
        displayMode: 'full',
        pageSizes: [5, 10, 'all'],
        showPageSizeSelector: true,
        showInfo: true,
        showNavButtons: true,
        errors:[],
        cliente_id:"",
        cliente_empresa:"",
        cliente_status:1,
        titleStatus:"Activar",
        titleModal:"",
        option:"save",
        cliente:{
            id:"",
            empresa:"",
            contacto:"",
            email:"",
            telefono:"",
            whatsapp:"",
            forma_de_pago:"",
            notas:"",
            status:"",
        },
        popupVisibleCreate: false,
        popupVisibleDelete:false,
        popupVisibleStatus:false,
        titleStatus:"Activar",
        searchModeOption: 'contains',
        searchExprOption: 'name',
        searchTimeoutOption: 200,
        minSearchLengthOption: 0,
        showDataBeforeSearchOption: false,
        isLoadPanelVisible: false,
        messageAlert:0,
        maxLength: null,
    }
  },
  methods: {
    onExporting(e) {
        if (e.format === 'xlsx') {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('Main sheet');
            exportDataGrid({
                component: e.component,
                worksheet: worksheet,
                customizeCell: function(options) {
                    const excelCell = options;
                    excelCell.font = { name: 'Arial', size: 12 };
                    excelCell.alignment = { horizontal: 'left' };
                } 
            }).then(function() {
                workbook.xlsx.writeBuffer()
                    .then(function(buffer) {
                        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'clientes.xlsx');
                    });
            });
            e.cancel = true;
        } 
        else if (e.format === 'pdf') {
            const doc = new jsPDF('l', 'mm', [297, 210]);
            exportDataGridToPdf({
                jsPDFDocument: doc,
                component: e.component
            }).then(() => {
                doc.save('clientes.pdf');
            })
        }
    },
    cambiarMsgAlert(value){
      this.messageAlert= value;
    },  
    cleanTextBox(){
      this.cliente.id="";
      this.cliente.empresa="";
      this.cliente.contacto="";
      this.cliente.email="";
      this.cliente.telefono="";
      this.cliente.whatsapp="";
      this.cliente.forma_de_pago="";
      this.cliente.notas="";
      this.cliente.status="";
    },
    agregarCliente(){
      this.cleanTextBox();
      this.showChildModal();
      this.titleModal="Nuevo cliente"
      this.option="save";
    },
    showChildModal(){
      this.popupVisibleCreate =  true;
    },
    nuevoCliente(){
      axios.post('clientes', this.cliente).then(response => {
        if(response && response.data.statusCode == 200){
          this.messageAlert = response.data.message;
          this.cleanTextBox();
          this.hideChildModal();
          this.getList();
				}
				else{
					console.log(response);
				}
			}).catch((error)=> {
				if (error.response.status == 422){
					this.errors = error.response.data.errors;
				}else{
					console.log(this.errors);
				}
			}); 
    },

    hideChildModal(){
      this.popupVisibleCreate =  false;
    },
    abrirModalEditar(value){
      this.titleModal="Editar cliente"
      this.option="edit";
      this.cleanTextBox();
      this.showChildModal();
      this.cliente_id = value.value;
      this.cliente_empresa=value.data.empresa;
      this.cliente_status = value.data.status;
      this.cliente.id = value.value;
      this.cliente.empresa=value.data.empresa;
      this.cliente.contacto=value.data.contacto;
      this.cliente.email=value.data.email;
      this.cliente.telefono=value.data.telefono;
      this.cliente.whatsapp=value.data.whatsapp;
      this.cliente.forma_de_pago=value.data.forma_de_pago;
      this.cliente.status = value.data.status;
      this.cliente.notas = value.data.notas;
    },
     cancelarEditar(){
      this.cleanTextBox();
      this.hideChildModal();
      this.errors=[];
    },
     onHidden(e){
      this.cancelarEditar();
    },

    abrirModalStatusFila(value){
      this.popupVisibleStatus=true;
      this.cliente_id = value.value;
      this.cliente_empresa = value.data.empresa;
      this.cliente_status = value.data.status;
      this.titleStatus =  value.data.status==1?'Desactivar':'Activar';  
    },
    abrirModalStatusEditar(){
      this.popupVisibleCreate =  false;
      this.popupVisibleStatus=true;
      this.cliente_status = this.cliente.status;
      this.titleStatus =  this.cliente.status==1?'Desactivar':'Activar';
      this.cliente_id = this.cliente.id;
      this.cliente_empresa = this.cliente.empresa;
    },
    cambiarStatus(){
      axios.post('banned-client/'+this.cliente_id).then(response=>{
        this.messageAlert = response.data.message;
        this.popupVisibleStatus=false;
        this.cleanTextBox();
        this.getList();
      });
    },
    cancelarStatus(){
      this.popupVisibleStatus=false;
      this.cliente_id = "";
      this.cliente_empresa = "";
    },
    abrirModalEliminar(value){
      this.popupVisibleDelete=true;
      this.cliente_id = value.value;
      this.cliente_empresa = value.data.empresa;
    },
    actualizarCliente(){
      axios.put('clientes/'+this.cliente_id, this.cliente).then(response => {
        if(response && response.data.statusCode == 200){
          this.messageAlert = response.data.message;
          this.cleanTextBox();
          this.hideChildModal();
          this.getList();
				}
				else{
					console.log(response);
				}
			}).catch((error)=> {
				if (error.response.status == 422){
					this.errors = error.response.data.errors;
				}else{
					console.log(this.errors);
				}
			}); 
    },
    cancelarEliminar(){
      this.popupVisibleDelete=false;
      this.cliente_id = "";
      this.cliente_empresa = "";
    },
    eliminar(){
      axios.delete('clientes/'+this.cliente_id).then(response => {
        if(response && response.data.statusCode == 200){
          this.messageAlert = response.data.message;
          this.cancelarEliminar();
          this.getList();
				}
				else{
					console.log(response);
				}
			}).catch((error)=> {
				if (error.response.status == 422){
					console.log(error);	
				}else{
					console.log(this.errors);
				}
			}); 
    },
    onHiddenDelete(e){
      this.cancelarEliminar();
    },
    onHiddenStatus(e){
      this.cancelarStatus;
    },
    getList(){
	    axios.get('listar-clientes').then(response=>{
        this.dataSource = response.data.clientes;
      })
    },
  },
  mounted(){
    this.getList();
  },
}
</script>
<style scoped>
  .small {
    font-size: smaller;
  }
  .error_message{
    color:#FF6868;
    font-weight:normal !important;
  }
  .active_password{
      font-size: smaller;
      color: #33FFA9;
  }
  .desactive_password{
    font-size: smaller;
    color: #C4C4C4;
    transition: all 0.3s;
  }
</style>
