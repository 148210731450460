<template>
  <div class="content">
    <div class="container-fluid">
        <div v-if="messageAlert">
          <Notification :message="messageAlert" @statusAlertMsg="cambiarMsgAlert" />
        </div>
        <div>
          <DxDataGrid
            id="gridContainer"
            :data-source="pedidos"
            key-expr="id"
            :column-hiding-enabled="true"
            :show-borders="true"
             @exporting="onExporting"
             :repaint-changes-only="true"
            @cell-prepared="onCellPrepared"
          >
            <DxFilterPanel :visible="true"/>
            <DxFilterBuilderPopup :position="filterBuilderPopupPosition"/>
            <DxHeaderFilter :visible="true"/>
            <DxFilterRow :visible="true"/>
            <DxGrouping :context-menu-enabled="true" expand-mode="rowClick" />
            <DxGroupPanel :visible="true" empty-panel-text="Use el menú contextual de las columnas de encabezado para agrupar datos" />
            <DxColumnChooser :enabled="true" mode="select" />
            <DxExport :enabled="true" :formats="['pdf', 'xlsx']"/>
            <DxColumn
              dataField="id"
              caption=""
              :showInColumnChooser="false"
              :allowFiltering="false"
              :allowSorting="false"
              cellTemplate="cellEditar"
              :fixed="true"
              width= '5%'
            /> 
            <DxColumn
              dataField="pedido"
              caption="Pedido"
              data-type="string"
              width= '10%'
            />
            <DxColumn
              dataField="estatus_nombre"
              caption="Estatus"
              data-type="string"
              width= '10%'
              >
              <DxLookup
                  :data-source="lookupData"
              />
            </DxColumn>
            <DxColumn dataField="website_sitio" caption="Sitio web" data-type="string"/>
            <DxColumn dataField="website_contacto" caption="Contacto" data-type="string"/>
            <DxColumn dataField="website_email" caption="Correo electrónico" data-type="string"/>
            <DxColumn dataField="url" caption="URL" data-type="string"/>
            <DxColumn dataField="notas" caption="Notas" data-type="string"/>
            <DxToolbar>
              <DxItem location="before" name="groupPanel" />
              <DxItem location="after" template="refreshTemplate" />
              <DxItem location="after" name="exportButton" />
              <DxItem location="after" name="columnChooserButton" />
            </DxToolbar>
            <template #refreshTemplate>
              <DxButton icon="plus" @click="agregarPedido()"/>
            </template> 
            <DxPager
              :allowed-page-sizes="[5, 8, 15, 30]"
              :show-info="true"
              :show-navigation-buttons="true"
              :show-page-size-selector="true"
              :visible="true"
            />
            <template #cellEditar="{ data }"> 
              <div class="text-center">
                <a href="#" class="text-decoration-none fas fa-pen fa-sm me-2" @click="abrirModalEditar( data )" title="Editar"></a>
                <a href="#" class="text-decoration-none fas fa-eye fa-sm" @click="abrirModalShow( data )" title="Mostrar"></a>
              </div>
            </template>
            <DxPaging :page-size="8"/>
          </DxDataGrid>
        </div>
        <dx-popup
          :height="'auto'"
          :showTitle="true"
          titleTemplate="titleModal"
          :dragEnabled="false"
          :hideOnOutsideClick="true"
          :visible="popupVisibleCreate"
          @hidden="onHidden"
        >
          <template #titleModal>
            <h3>{{titleModal}}</h3>
          </template>
          <div>
            <form action="">
              <div class="form">
                <div class="dx-fieldset">
                  <div class="row mb-2">
                    <div class="col-md-4">
                       <DxSelectBox
                        label="Cliente:"
                        :search-enabled="true"
                        :data-source="clientes"
                        :search-mode="searchModeOption"
                        :search-expr="searchExprOptionCliente"
                        :search-timeout="searchTimeoutOption"
                        :min-search-length="minSearchLengthOption"
                        :show-data-before-search="showDataBeforeSearchOption"
                        display-expr="empresa"
                        value-expr="id"
                        v-model="pedido.cliente_id"
                      />
                      <small class="error_message" v-for="(e_cliente_id, index) in errors.cliente_id" :key="index">{{e_cliente_id}}</small>
                    </div>
                    <div class="col-md-4">
                       <DxSelectBox
                        label="Website:"
                        :search-enabled="true"
                        :data-source="websites"
                        :search-mode="searchModeOption"
                        :search-expr="searchExprOptionWebsite"
                        :search-timeout="searchTimeoutOption"
                        :min-search-length="minSearchLengthOption"
                        :show-data-before-search="showDataBeforeSearchOption"
                        display-expr="sitio"
                        value-expr="id"
                        v-model="pedido.website_id"
                      />
                      <small class="error_message" v-for="(e_website_id, index) in errors.website_id" :key="index">{{e_website_id}}</small>
                    </div>
                    <div class="col-md-4">
                      <DxTextBox label="Título:*" v-model="pedido.titulo" />
                      <small class="error_message" v-for="(e_titulo, index) in errors.titulo" :key="index">{{e_titulo}}</small>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-6">
                      <DxTextBox v-if="option=='edit'" label="URL:*" v-model="pedido.url" />
                    </div>
                    <div class="col-md-6">
                      <DxCheckBox v-if="option=='edit'" :value="false" text="Enviado" v-model="pedido.enviado"/>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-6">
                       <DxSelectBox
                        label="Tipo:"
                        :search-enabled="true"
                        :data-source="tipoWebsites"
                        :search-mode="searchModeOption"
                        :search-expr="searchExprOption"
                        :search-timeout="searchTimeoutOption"
                        :min-search-length="minSearchLengthOption"
                        :show-data-before-search="showDataBeforeSearchOption"
                        display-expr="tipo"
                        value-expr="tipo"
                        v-model="pedido.tipo"
                      />
                      <small class="error_message" v-for="(e_tipo, index) in errors.tipo" :key="index">{{e_tipo}}</small>
                    </div>
                    <div class="col-md-6">
                       <DxTextArea
                        label="Notas:"
                        :height="80"
                        :max-length="maxLength"
                        v-model="pedido.notas"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <dx-button class="me-2" type="normal" text="Cancelar" @click="cancelarEditar()"></dx-button>
                      <dx-button class="me-2" v-if="option=='save'" icon="save" type="default" text="Guardar" @click="nuevoPedido()"></dx-button>
                      <dx-button class="me-2" v-if="option=='edit'" icon="fas fa-lock fa-sm" type="default" text="Guardar" @click="actualizarPedido()"></dx-button>
                    </div>
                  </div>
                </div>
              </div>
            </form>            
          </div>
        </dx-popup>
        <dx-popup
          :height="'auto'"
          :showTitle="true"
          titleTemplate="titleModal"
          :dragEnabled="false"
          :hideOnOutsideClick="true"
          :visible="popupVisibleShow"
          @hidden="onHiddenShow"
        >
          <template #titleModal>
            <h3>Pedido {{pedido.pedido}}</h3>
          </template>
          <div>
            <h3>Pedido: {{pedido.pedido}} / {{pedido.titulo}}</h3>
            <div class="row mb-2">
              <div class="col-md-4">
                <span>Cliente: {{pedido.empresa}}</span>
              </div>
              <div class="col-md-4">
                <span>Website: {{pedido.website_sitio}}</span>
              </div>
              <div class="col-md-4">
                <span>Tipo: {{pedido.tipo}}</span>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-6">
                <span>URL: {{pedido.url}}</span>
              </div>
              <div class="col-md-6">Estatus:
                <a class="btn-custom" :style="{background: pedido.estatus_color +' 0% 0% no-repeat padding-box'}">
                    {{pedido.estatus_nombre}}
                </a>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-12">
                <span>Notas pedido: {{pedido.notas}}</span>
              </div>
            </div>
            <br>
            <hr>
            <div class="row">
              <div class="col-md-12 d-flex justify-content-end">
                <dx-button class="me-2" type="normal" text="Cancelar" @click="cancelarShow()"></dx-button>
              </div>
            </div>
          </div>
        </dx-popup>
    </div>
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxScrolling,
  DxPager,
  DxExport,
  DxHeaderFilter,
  DxFilterRow,
  DxColumnFixing,
  DxColumnChooser,
  DxSelection,
  DxStateStoring,
  DxGroupPanel,
  DxToolbar,
  DxEditing,
  DxGrouping,
  DxFilterPanel,
  DxFilterBuilderPopup,
} from 'devextreme-vue/data-grid';
import {
    DxValidator,
    DxRequiredRule,
    DxCustomRule
} from 'devextreme-vue/validator';

import { DxTextArea } from 'devextreme-vue/text-area';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxItem } from 'devextreme-vue/form';
import { DxPopup } from 'devextreme-vue/popup';
import { DxButton } from 'devextreme-vue/button';
import { DxCheckBox } from 'devextreme-vue';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { jsPDF } from 'jspdf';
import { exportDataGrid as exportDataGridToPdf} from 'devextreme/pdf_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import axios from "axios";
import Notification from "./NotificationComponent";
import 'devextreme/dist/css/dx.light.css';
import "bootstrap/dist/css/bootstrap.min.css";
import moment from 'moment';
import 'moment-timezone';
const lookupData = [
    'Recibido',
    'Enviado',
    'Publicado',
    'Por pagar',
    'Pagado',
    'Cancelado',
    'En correción'
];

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxLookup,
        DxForm,
        DxItem,
        DxTextArea,
        DxScrolling,
        DxPager,
        DxExport,
        DxHeaderFilter,
        DxFilterRow,
        DxColumnFixing,
        DxColumnChooser,
        DxSelection,
        DxStateStoring,
        DxGroupPanel,
        DxPopup,
        DxButton,
        DxTextBox,
        DxToolbar,
        DxValidator,
        DxRequiredRule,
        DxCustomRule,
        DxCheckBox,
        DxSelectBox,
        DxLoadPanel,
        Notification,
        DxEditing,
        DxGrouping,
        DxFilterPanel,
        DxFilterBuilderPopup,
  },
  data() {
    return {
        lookupData,
        filterBuilderPopupPosition: {
          of: window,
          at: 'top',
          my: 'top',
          offset: { y: 10 },
        },  
        maxLength: null,
        pedidos:[],
        websites:[],
        clientes:[],
        pedido:{
          pedido:'',
          id:'',
          cliente_id:'',
          empresa:'',
          website_id:'',
          titulo:'',
          tipo:'',
          url:'',
          enviado:'',
          notas:'',
          website_sitio:'',
          estatus_nombre:'',
          estatus_color:'',
        },
        tipoWebsites:[
          {tipo:'General'},
          {tipo:'Gambling'},
          {tipo:'Especial'},
        ],
        displayModes: [{ text: 'Display Mode \'full\'', value: 'full' }, { text: 'Display Mode \'compact\'', value: 'compact' }],
        displayMode: 'full',
        pageSizes: [5, 10, 'all'],
        showPageSizeSelector: true,
        showInfo: true,
        showNavButtons: true,
        errors:[],
        pedido_id:"",
        titleStatus:"Activar",
        titleModal:"",
        option:"save",
        popupVisibleCreate: false,
        popupVisibleDelete:false,
        popupVisibleStatus:false,
        popupVisibleShow:false,
        titleStatus:"Activar",
        searchModeOption: 'contains',
        searchExprOptionWebsite: 'sitio',
        searchExprOptionCliente: 'empresa',
        searchExprOption: 'tipo',
        searchTimeoutOption: 200,
        minSearchLengthOption: 0,
        showDataBeforeSearchOption: false,
        isLoadPanelVisible: false,
        messageAlert:0,
    }
  },
  methods: {
    cancelarShow(){
      this.cleanTextBox();
      this.hideChildModalShow();
      this.errors=[];
    },
    onHiddenShow(e){
      this.cancelarShow();
    },
    hideChildModalShow(){
      this.popupVisibleShow =  false;
    },
    showChildModalShow(){
      this.popupVisibleShow =  true;
    },
    abrirModalShow(value){
      this.option="show";
      this.showChildModalShow();
      this.pedido.pedido=value.data.pedido;
      this.pedido.cliente_id=value.data.cliente_id;
      this.pedido.empresa=value.data.empresa;
      this.pedido.titulo=value.data.titulo;
      this.pedido.tipo=value.data.tipo;
      this.pedido.url=value.data.url;
      this.pedido.notas = value.data.notas;  
      this.pedido.website_sitio=value.data.website_sitio;
      this.pedido.estatus_nombre=value.data.estatus_nombre;
      this.pedido.estatus_color=value.data.estatus_color;
    },
    onCellPrepared(e){
        if(e.rowType === "data" && e.column.dataField === "estatus_nombre") {
          e.cellElement.style.backgroundColor =  e.data.estatus_color; 
          e.cellElement.style.color =  "white"; 
          e.cellElement.style.borderRadius =  "15px";
          e.cellElement.style.textAlign = "center"
          e.watch(function() {
              return e.data.estatus_nombre;
          }, function() {
              e.cellElement.style.backgroundColor =  "green"; 
              e.cellElement.style.color =  "white"; 
              e.cellElement.style.borderRadius =  "15px";
              e.cellElement.style.textAlign = "center"
          })
        }
    },
    onExporting(e) {
        e.component.columnOption('id', 'visible', false);
        e.component.columnOption('notas', 'visible', false);
        if (e.format === 'xlsx') {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('Main sheet');
            exportDataGrid({
                component: e.component,
                worksheet: worksheet,
                customizeCell: function(options) {
                    const excelCell = options;
                    excelCell.font = { name: 'Arial', size: 12 };
                    excelCell.alignment = { horizontal: 'left' };
                } 
            }).then(function() {
                workbook.xlsx.writeBuffer()
                    .then(function(buffer) {
                      e.component.columnOption('id', 'visible', true);
                      e.component.columnOption('notas', 'visible', true);
                        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'pedidos.xlsx');
                    });
            });
            e.cancel = true;
        } 
        else if (e.format === 'pdf') {
            const doc = new jsPDF('l', 'mm', [297, 210]);
            exportDataGridToPdf({
                jsPDFDocument: doc,
                component: e.component
            }).then(() => {
                e.component.columnOption('id', 'visible', true);
                e.component.columnOption('notas', 'visible', true);
                doc.save('pedidos.pdf');
            })
        }
    },
    cambiarMsgAlert(value){
      this.messageAlert= value;
    },  
    cleanTextBox(){
      this.pedido.id="";
      this.pedido.website_id="";
      this.pedido.cliente_id="";
      this.pedido.titulo="";
      this.pedido.tipo="";
      this.pedido.url="";
      this.pedido.enviado="";
      this.pedido.notas="";
    },
    agregarPedido(){
      this.cleanTextBox();
      this.showChildModal();
      this.titleModal="Nuevo pedido"
      this.option="save";
    },
    showChildModal(){
      this.popupVisibleCreate =  true;
    },
    nuevoPedido(){
      axios.post('pedidos', this.pedido).then(response => {
        if(response && response.data.statusCode == 200){
          this.messageAlert = response.data.message;
          this.cleanTextBox();
          this.hideChildModal();
          this.getList();
				}
				else{
					console.log(response);
				}
			}).catch((error)=> {
				if (error.response.status == 422){
					this.errors = error.response.data.errors;
				}else{
					console.log(this.errors);
				}
			}); 
    },
    hideChildModal(){
      this.popupVisibleCreate =  false;
    },
    abrirModalEditar(value){
      this.titleModal="Editar pedido"
      this.option="edit";
      this.cleanTextBox();
      this.showChildModal();
      this.pedido_id = value.value;
      this.pedido.id = value.value;
      this.pedido.cliente_id=value.data.cliente_id;
      this.pedido.website_id=value.data.website_id;
      this.pedido.titulo=value.data.titulo;
      this.pedido.tipo=value.data.tipo;
      this.pedido.url=value.data.url;
      this.pedido.notas = value.data.notas;
      this.pedido.enviado = value.data.enviado;
    },
     cancelarEditar(){
      this.cleanTextBox();
      this.hideChildModal();
      this.errors=[];
    },
     onHidden(e){
      this.cancelarEditar();
    },
    actualizarPedido(){
      axios.put('pedidos/'+this.pedido_id, this.pedido).then(response => {
        if(response && response.data.statusCode == 200){
          this.messageAlert = response.data.message;
          this.cleanTextBox();
          this.hideChildModal();
          this.getList();
				}
				else{
					console.log(response);
				}
			}).catch((error)=> {
				if (error.response.status == 422){
					this.errors = error.response.data.errors;
				}else{
					console.log(this.errors);
				}
			}); 
    },
    getList(){
      let hoy = moment().tz('America/Mexico_City');
	    axios.get('listar-pedidos').then(response=>{
        let pedidos = response.data.pedidos;
        pedidos.map(function(dato){
          let fecha_estatus = moment(dato.fecha_estatus).tz('America/Mexico_City');
          let horas = hoy.diff(fecha_estatus, 'hours');
          if(dato.estatus_id == 3 && horas > 24){
            dato.estatus_id = 4;
            dato.estatus_nombre = "Por pagar";
            dato.estatus_color = "#0000FF";
          }
          dato.pedido = 'P'+dato.pedido;
          return dato;
        });
      this.pedidos = pedidos;
      this.websites = response.data.websites;
      this.clientes = response.data.clientes;
      })
    },
  },
  mounted(){
    this.getList();
  },
}
</script>
<style scoped>
  .small {
    font-size: smaller;
  }
  .error_message{
    color:#FF6868;
    font-weight:normal !important;
  }
  .active_password{
      font-size: smaller;
      color: #33FFA9;
  }
  .desactive_password{
    font-size: smaller;
    color: #C4C4C4;
    transition: all 0.3s;
  }

  .btn-custom {
    display: inline-block;
    color: #fff;
    letter-spacing: 0;
    border-radius: 15px;
    padding: 2px 18px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    text-decoration: none;
  }
</style>
