<template>
  <div class="content">
    <div class="container-fluid">
        <div v-if="messageAlert">
          <Notification :message="messageAlert" @statusAlertMsg="cambiarMsgAlert" />
        </div>
        <div>
          <DxDataGrid
            id="gridContainer"
            :data-source="pedidos"
            key-expr="id"
            :column-hiding-enabled="true"
            :show-borders="true"
            @exporting="onExporting"
            :repaint-changes-only="true"
            @cell-prepared="onCellPrepared"
          >
            <DxFilterPanel :visible="true"/>
            <DxFilterBuilderPopup :position="filterBuilderPopupPosition"/>
            <DxHeaderFilter :visible="true"/>
            <DxFilterRow :visible="true"/>
            <DxGrouping :context-menu-enabled="true" expand-mode="rowClick" />
            <DxGroupPanel :visible="true" empty-panel-text="Use el menú contextual de las columnas de encabezado para agrupar datos" />
            <DxColumnChooser :enabled="true" mode="select" />
            <DxExport :enabled="true" :formats="['pdf', 'xlsx']"/>
            <DxColumn
              dataField="id"
              caption=""
              :showInColumnChooser="false"
              :allowFiltering="false"
              :allowSorting="false"
              cellTemplate="cellEditar"
              width="5%"
            /> 
            <DxColumn
              dataField="pedido"
              caption="Pedido"
              data-type="string"
              width="8%"
            />
            <DxColumn
              dataField="estatus_c_nombre"
              caption="Estatus C"
              data-type="string"
               width='100px'
              >
              <DxLookup
                  :data-source="lookupDataC"
              />
            </DxColumn>
            <DxColumn dataField="empresa" caption="Cliente" data-type="string"/>
            <DxColumn dataField="website_sitio" caption="Sitio web" data-type="string"/>
            <DxColumn
              dataField="estatus_nombre"
              caption="Estatus"
              data-type="string"
              width='100px'
              >
              <DxLookup
                  :data-source="lookupData"
              />
            </DxColumn>
            <DxColumn
                dataField="costo"
                caption="Costo"
                data-type="number"
                format="currency"  
                width="10%"
            />
            <DxColumn
                dataField="precio"
                caption="Precio"
                data-type="number"
                format="currency"  
                width="10%"
            />
            <DxColumn
                dataField="fecha"
                caption="Fecha"
                data-type="date"
                format="dd/MM/yyyy HH:mm:ss"
                />
            <DxColumn
              dataField="url"
              caption="URL"
              data-type="string"
              />
            <DxToolbar>
              <DxItem location="before" name="groupPanel" />
              <DxItem location="after" name="exportButton" />
              <DxItem location="after" name="columnChooserButton" />
            </DxToolbar>
            <DxPager
              :allowed-page-sizes="[50, 100, 150, 200]"
              :show-info="true"
              :show-navigation-buttons="true"
              :show-page-size-selector="true"
              :visible="true"
            />
            <template #cellEditar="{ data }"> 
              <div class="text-center">
                <a href="#" class="me-2 text-decoration-none fas fa-pen fa-sm" @click="abrirModalEditar( data )" title="Editar"></a>
                <a href="#" class="text-decoration-none fas fa-eye fa-sm" @click="abrirModalShow( data )" title="Mostrar"></a>
              </div>
            </template>
            <DxPaging :page-size="50"/>
          </DxDataGrid>
        </div>
        <dx-popup
          :height="'auto'"
          :showTitle="true"
          titleTemplate="titleModal"
          :dragEnabled="false"
          :hideOnOutsideClick="true"
          :visible="popupVisibleCreate"
          @hidden="onHidden"
        >
          <template #titleModal>
            <h3>{{titleModal}}</h3>
          </template>
          <div>
            <form action="">
              <div class="form">
                <div class="dx-fieldset">
                  <div class="row mb-2">
                    <div class="col-md-6">
                       <DxSelectBox
                        label="Cliente:"
                        :search-enabled="true"
                        :data-source="clientes"
                        :search-mode="searchModeOption"
                        :search-expr="searchExprOptionCliente"
                        :search-timeout="searchTimeoutOption"
                        :min-search-length="minSearchLengthOption"
                        :show-data-before-search="showDataBeforeSearchOption"
                        display-expr="empresa"
                        value-expr="id"
                        v-model="pedido.cliente_id"
                      />
                      <small class="error_message" v-for="(e_cliente_id, index) in errors.cliente_id" :key="index">{{e_cliente_id}}</small>
                    </div>
                    <div class="col-md-6">
                       <DxSelectBox
                        label="Website:"
                        :search-enabled="true"
                        :data-source="websites"
                        :search-mode="searchModeOption"
                        :search-expr="searchExprOptionWebsite"
                        :search-timeout="searchTimeoutOption"
                        :min-search-length="minSearchLengthOption"
                        :show-data-before-search="showDataBeforeSearchOption"
                        display-expr="sitio"
                        value-expr="id"
                        v-model="pedido.website_id"
                      />
                      <small class="error_message" v-for="(e_website_id, index) in errors.website_id" :key="index">{{e_website_id}}</small>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-6">
                      <DxTextBox label="Título:*" v-model="pedido.titulo" />
                      <small class="error_message" v-for="(e_titulo, index) in errors.titulo" :key="index">{{e_titulo}}</small>
                    </div>
                    <div class="col-md-6">
                      <DxTextBox label="URL:*" v-model="pedido.url" />
                      <small class="error_message" v-for="(e_url, index) in errors.url" :key="index">{{e_url}}</small>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-3">
                      <dx-select-box
                          label="Divisa costo:"
                          :search-enabled="true"
                          :data-source="divisas"
                          :search-mode="searchModeOption"
                          :search-expr="searchExprOptionDivisaCosto"
                          :search-timeout="searchTimeoutOption"
                          :min-search-length="minSearchLengthOption"
                          :show-data-before-search="showDataBeforeSearchOption"
                          display-expr="descripcion"
                          value-expr="descripcion"
                          v-model="pedido.divisa_costo"
                          width="200px"
                      ></dx-select-box>
                      <small style="color:#FF6868;" v-for="(e_divisa_costo, index) in errors.divisa_costo" :key="index">{{e_divisa_costo}}</small>
                    </div>
                    <div class="col-md-3">
                        <DxNumberBox
                            label="Costo:*"
                            v-model="pedido.costo"
                            format="$ #,##0.##"
                        />
                      <small class="error_message" v-for="(e_costo, index) in errors.costo" :key="index">{{e_costo}}</small>
                    </div>
                    <div class="col-md-3">
                      <dx-select-box
                        label="Divisa precio:"
                        :search-enabled="true"
                        :data-source="divisas"
                        :search-mode="searchModeOption"
                        :search-expr="searchExprOptionDivisaPrecio"
                        :search-timeout="searchTimeoutOption"
                        :min-search-length="minSearchLengthOption"
                        :show-data-before-search="showDataBeforeSearchOption"
                        display-expr="descripcion"
                        value-expr="descripcion"
                        v-model="pedido.divisa_precio"
                        width="200px"
                      ></dx-select-box>
                      <small style="color:#FF6868;" v-for="(e_divisa_precio, index) in errors.divisa_precio" :key="index">{{e_divisa_precio}}</small>
                       
                    </div>

                    <div class="col-md-3">
                        <DxNumberBox
                            label="Precio:*"
                            v-model="pedido.precio"
                            format="$ #,##0.##"
                        />
                      <small class="error_message" v-for="(e_precio, index) in errors.precio" :key="index">{{e_precio}}</small>
                    </div>
                  </div>
                   <div class="row mb-2">
                    <div class="col-md-6">
                      <DxSelectBox
                        label="Estatus:"
                        :search-enabled="true"
                        :data-source="estatus"
                        :search-mode="searchModeOption"
                        :search-expr="searchExprOptionEstatus"
                        :search-timeout="searchTimeoutOption"
                        :min-search-length="minSearchLengthOption"
                        :show-data-before-search="showDataBeforeSearchOption"
                        display-expr="nombre"
                        value-expr="id"
                        v-model="pedido.estatus_id"
                      />
                      <small class="error_message" v-for="(e_estatus, index) in errors.estatus" :key="index">{{e_estatus}}</small>
                    </div>
                    <div class="col-md-6">
                      <DxSelectBox
                        label="Estatus C:"
                        :search-enabled="true"
                        :data-source="estatus_c"
                        :search-mode="searchModeOption"
                        :search-expr="searchExprOptionEstatusC"
                        :search-timeout="searchTimeoutOption"
                        :min-search-length="minSearchLengthOption"
                        :show-data-before-search="showDataBeforeSearchOption"
                        display-expr="nombre"
                        value-expr="id"
                        v-model="pedido.estatus_c_id"
                      />
                      <small class="error_message" v-for="(e_estatus_c, index) in errors.estatus_c" :key="index">{{e_estatus_c}}</small>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-12">
                       <DxTextArea
                        label="Notas:"
                        :height="80"
                        :max-length="maxLength"
                        v-model="pedido.notas"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 d-flex justify-content-end">
                      <dx-button class="me-2" type="normal" text="Cancelar" @click="cancelarEditar()"></dx-button>
                      <dx-button class="me-2" v-if="option=='save'" icon="save" type="default" text="Guardar" @click="nuevoPedido()"></dx-button>
                      <dx-button class="me-2" v-if="option=='edit'" icon="fas fa-lock fa-sm" type="default" text="Guardar" @click="actualizarPedido()"></dx-button>
                    </div>
                  </div>
                </div>
              </div>
            </form>            
          </div>
        </dx-popup>
        <dx-popup
          :height="'auto'"
          :showTitle="true"
          titleTemplate="titleModal"
          :dragEnabled="false"
          :hideOnOutsideClick="true"
          :visible="popupVisibleShow"
          @hidden="onHiddenShow"
        >
          <template #titleModal>
            <h3>Pedido {{pedido.pedido}}</h3>
          </template>
          <div>
            <div class="row mb-2">
              <div class="col-md-8">
                <h3>{{pedido.titulo}}</h3>
              </div>
              <div class="col-md-4">
                 <h3>Tipo: {{pedido.tipo}}</h3>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-3">
                <span>Divisa costo: {{pedido.divisa_costo}}</span>
              </div>
              <div class="col-md-3">
                <span>Costo: {{pedido.costo}}</span>
              </div>
              <div class="col-md-3">
                <span>Divisa precio: {{pedido.divisa_precio}}</span>
              </div>
              <div class="col-md-3">
                <span>Precio: {{pedido.precio}}</span>
              </div>
            </div>
            <div class="row  mb-2">
              <div class="col-md-3">Estatus:
                <a class="btn-custom" :style="{background: pedido.estatus_color +' 0% 0% no-repeat padding-box'}">
                    {{pedido.estatus_nombre}}
                </a>
              </div>
              <div class="col-md-3">
                <span>Fecha estatus: {{pedido.fecha_estatus}}</span>
              </div>
              <div class="col-md-3">Estatus C:
                <a class="btn-custom" :style="{background: pedido.estatus_c_color +' 0% 0% no-repeat padding-box'}">
                    {{pedido.estatus_c_nombre}}
                </a>
              </div>
              <div class="col-md-3">
                <span>Fecha estatus C: {{pedido.fecha_estatus_c}}</span>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-12">
                <span>Notas pedido: {{pedido.notas}}</span>
              </div>
            </div>
            <hr>
            <h3>Website</h3>
            <div class="row">
              <div class="col-md-4">
                <span>{{pedido.website_sitio}}</span>
              </div>
              <div class="col-md-4">
                <span>Contacto: {{pedido.website_contacto}}</span>
              </div>
              <div class="col-md-4">
                <span>Correo electrónico: {{pedido.website_email}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <span>Datos de pago: {{pedido.datos_de_pago}}</span>
              </div>
            </div>
            <hr>
            <h3>Cliente</h3>
            <div class="row">
              <div class="col-md-4">
                <span>{{pedido.empresa}}</span>
              </div>
              <div class="col-md-4">
                <span>Contacto: {{pedido.cliente_contacto}}</span>
              </div>
              <div class="col-md-4">
                <span>Correo electrónico: {{pedido.cliente_email}}</span>
              </div>
            </div>
            <br>
            <hr>
            <div class="row">
              <div class="col-md-12">
                Url: <input style="width: 100%;"  type="text" name="url" id="url" :value="pedido.url" @click="copiarURL()">
                <span v-if="urlcopy==1"> <strong>¡Url copiada!</strong></span>
                <!-- <span @click="copiarURL(pedido.url)">URL:{{pedido.url}}</span> -->
              </div>
            </div>
            <br>
            <hr>
            <div class="row">
              <div class="col-md-12 d-flex justify-content-end">
                <dx-button class="me-2" type="normal" text="Cancelar" @click="cancelarShow()"></dx-button>
              </div>
            </div>
          </div>
        </dx-popup>
    </div>
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxLookup,
  DxForm,
  DxScrolling,
  DxPager,
  DxExport,
  DxHeaderFilter,
  DxFilterRow,
  DxColumnFixing,
  DxColumnChooser,
  DxSelection,
  DxStateStoring,
  DxGroupPanel,
  DxToolbar,
  DxEditing,
  DxGrouping,
  DxFilterPanel,
  DxFilterBuilderPopup,
} from 'devextreme-vue/data-grid';
import {
    DxValidator,
    DxRequiredRule,
    DxCustomRule
} from 'devextreme-vue/validator';

import { DxTextArea } from 'devextreme-vue/text-area';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxItem } from 'devextreme-vue/form';
import { DxPopup } from 'devextreme-vue/popup';
import { DxButton } from 'devextreme-vue/button';
import { DxCheckBox } from 'devextreme-vue';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import { DxNumberBox } from 'devextreme-vue/number-box';
import { jsPDF } from 'jspdf';
import { exportDataGrid as exportDataGridToPdf} from 'devextreme/pdf_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportDataGrid } from 'devextreme/excel_exporter';
import axios from "axios";
import Notification from "./NotificationComponent";
import 'devextreme/dist/css/dx.light.css';
import "bootstrap/dist/css/bootstrap.min.css";
import moment from 'moment';
import 'moment-timezone';
const lookupData = [
    'Recibido',
    'Enviado',
    'Publicado',
    'Por pagar',
    'Pagado',
    'Cancelado',
    'En correción'
];

const lookupDataC = [
    'Recibido',
    'Por cobrar',
    'Pagado',
];

const divisas = [{
    descripcion: 'USD',
  },
  {
    descripcion: 'EUR',
  },
  {
    descripcion: 'MXN',
  }, 
];

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxLookup,
        DxForm,
        DxItem,
        DxTextArea,
        DxScrolling,
        DxPager,
        DxExport,
        DxHeaderFilter,
        DxFilterRow,
        DxColumnFixing,
        DxColumnChooser,
        DxSelection,
        DxStateStoring,
        DxGroupPanel,
        DxPopup,
        DxButton,
        DxTextBox,
        DxNumberBox,
        DxToolbar,
        DxValidator,
        DxRequiredRule,
        DxCustomRule,
        DxCheckBox,
        DxSelectBox,
        DxLoadPanel,
        Notification,
        DxEditing,
        DxGrouping,
        DxFilterPanel,
        DxFilterBuilderPopup,
  },
  data() {
    return {
        divisas,
        lookupData,
        lookupDataC,
        filterBuilderPopupPosition: {
          of: window,
          at: 'top',
          my: 'top',
          offset: { y: 10 },
        },  
        maxLength: null,
        pedidos:[],
        websites:[],
        clientes:[],
        estatus:[],
        estatus_c:[],
        pedido:{
            id:'',
            cliente_id:'',
            empresa:'',
            cliente_contacto:'',
            cliente_email:'',
            website_id:'',
            website_sitio:'',
            website_contacto:'',
            website_email:'',
            datos_de_pago:'',
            titulo:'',
            divisa_costo:"",
            divisa_precio:"",
            costo:'',
            precio:'',
            url:'',
            notas:'',
            estatus_id:'',
            estatus_c_id:'',
            pedido:'',
            tipo:'',
            costo:'',
            precio:'',
            estatus_nombre:'',
            estatus_color:'',
            estatus_c_nombre:'',
            estatus_c_color:'',
            fecha_estatus:'',
            fecha_estatus_c:'',
        },
        tipoWebsites:[
          {tipo:'General'},
          {tipo:'Gambling'},
          {tipo:'Especial'},
        ],
        displayModes: [{ text: 'Display Mode \'full\'', value: 'full' }, { text: 'Display Mode \'compact\'', value: 'compact' }],
        displayMode: 'full',
        pageSizes: [5, 10, 'all'],
        showPageSizeSelector: true,
        showInfo: true,
        showNavButtons: true,
        errors:[],
        pedido_id:"",
        titleStatus:"Activar",
        titleModal:"",
        option:"save",
        popupVisibleCreate: false,
        popupVisibleShow:false,
        titleStatus:"Activar",
        searchModeOption: 'contains',
        searchExprOptionWebsite: 'sitio',
        searchExprOptionCliente: 'empresa',
        searchExprOptionEstatus: 'nombre',
        searchExprOptionEstatusC: 'nombre',
        searchTimeoutOption: 200,
        minSearchLengthOption: 0,
        showDataBeforeSearchOption: false,
        isLoadPanelVisible: false,
        messageAlert:0,
        searchExprOptionDivisaCosto: 'descripcion',
        searchExprOptionDivisaPrecio: 'descripcion',
        urlcopy:0,
    }
  },
  methods: {
    copiarURL(){
      const url = document.querySelector('#url')
      url.select();
      document.execCommand('copy');
      this.urlcopy = 1;
    },
    onCellPrepared(e){
        if(e.rowType === "data" && e.column.dataField === "estatus_nombre") {
          e.cellElement.style.backgroundColor =  e.data.estatus_color; 
          e.cellElement.style.color =  "white"; 
          e.cellElement.style.borderRadius =  "15px";
          e.cellElement.style.textAlign = "center"
          e.watch(function() {
            return e.data.estatus_nombre;
          }, function() {
            e.cellElement.style.backgroundColor =  e.data.estatus_color; 
            e.cellElement.style.color =  "white"; 
            e.cellElement.style.borderRadius =  "15px";
            e.cellElement.style.textAlign = "center"
          })
        }

        if(e.rowType === "data" && e.column.dataField === "estatus_c_nombre") {
          e.cellElement.style.backgroundColor =  e.data.estatus_c_color; 
          e.cellElement.style.color =  "white"; 
          e.cellElement.style.borderRadius =  "15px";
          e.cellElement.style.textAlign = "center"
          e.watch(function() {
            return e.data.estatus_c_nombre;
          }, function() {
              e.cellElement.style.backgroundColor =  e.data.estatus_c_color; 
              e.cellElement.style.color =  "white"; 
              e.cellElement.style.borderRadius =  "15px";
              e.cellElement.style.textAlign = "center"
          })
        }
    },
    onExporting(e) {
        e.component.columnOption('id', 'visible', false);
        if (e.format === 'xlsx') {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('Main sheet');
            exportDataGrid({
                component: e.component,
                worksheet: worksheet,
                customizeCell: function(options) {
                    const excelCell = options;
                    excelCell.font = { name: 'Arial', size: 12 };
                    excelCell.alignment = { horizontal: 'left' };
                } 
            }).then(function() {
                workbook.xlsx.writeBuffer()
                    .then(function(buffer) {
                        e.component.columnOption('id', 'visible', true);
                        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'pedidos.xlsx');
                    });
            });
            e.cancel = true;
        } 
        else if (e.format === 'pdf') {
            const doc = new jsPDF('l', 'mm', [297, 210]);
            exportDataGridToPdf({
                jsPDFDocument: doc,
                component: e.component
            }).then(() => {
               e.component.columnOption('id', 'visible', true);
                doc.save('pedidos.pdf');
            })
        }
    },
    cambiarMsgAlert(value){
      this.messageAlert= value;
    },  
    cleanTextBox(){
      this.pedidos.id="";
      this.pedidos.cliente_id="";
      this.pedidos.empresa="";
      this.pedidos.cliente_contacto="";
      this.pedidos.cliente_email="";
      this.pedidos.website_id="";
      this.pedidos.website_sitio="";
      this.pedidos.website_contacto="";
      this.pedidos.website_email="";
      this.pedidos.titulo="";
      this.pedidos.divisa_costo="";
      this.pedidos.divisa_precio="";
      this.pedidos.costo="";
      this.pedidos.precio="";
      this.pedidos.url="";
      this.pedidos.notas="";
      this.pedidos.estatus_id="";
      this.pedidos.estatus_c_id="";
      this.pedidos.pedido="";
      this.pedidos.tipo="";
      this.pedidos.costo="";
      this.pedidos.precio="";
      this.pedidos.estatus_nombre="";
      this.pedidos.estatus_color="";
      this.pedidos.estatus_c_nombre="";
      this.pedidos.estatus_c_color="";
      this.pedidos.fecha_estatus="";
      this.pedidos.fecha_estatus_c="";
    },
    agregarPedido(){
      this.cleanTextBox();
      this.showChildModal();
      this.titleModal="Nuevo pedido"
      this.option="save";
    },
    showChildModal(){
      this.popupVisibleCreate =  true;
    },
    showChildModalShow(){
      this.popupVisibleShow =  true;
    },
    nuevoPedido(){
      axios.post('pedidos', this.pedido).then(response => {
        if(response && response.data.statusCode == 200){
          this.messageAlert = response.data.message;
          this.cleanTextBox();
          this.hideChildModal();
          this.getList();
				}
				else{
					console.log(response);
				}
			}).catch((error)=> {
				if (error.response.status == 422){
					this.errors = error.response.data.errors;
				}else{
					console.log(this.errors);
				}
			}); 
    },
    hideChildModal(){
      this.popupVisibleCreate =  false;
    },
    hideChildModalShow(){
      this.popupVisibleShow =  false;
    },
    abrirModalEditar(value){
        this.titleModal="Editar pedido"
        this.option="edit";
        this.cleanTextBox();
        this.showChildModal();
        this.pedido_id = value.value;
        this.pedido.id = value.value;
        this.pedido.cliente_id=value.data.cliente_id;
        this.pedido.website_id=value.data.website_id;
        this.pedido.titulo=value.data.titulo;
        this.pedido.divisa_costo=value.data.divisa_costo;
        this.pedido.divisa_precio=value.data.divisa_precio;
        this.pedido.costo=value.data.costo_org;
        this.pedido.precio=value.data.precio_org;
        this.pedido.estatus_id=value.data.estatus_id;
        this.pedido.estatus_c_id=value.data.estatus_c_id;
        this.pedido.url=value.data.url;
        this.pedido.notas = value.data.notas;  
    },
    abrirModalShow(value){
      this.urlcopy=0;
      this.option="show";
      this.showChildModalShow();
      this.pedido.id = value.value;
      this.pedido.pedido=value.data.pedido;
      this.pedido.titulo=value.data.titulo;
      this.pedido.tipo=value.data.tipo;
      this.pedido.divisa_costo=value.data.divisa_costo;
      this.pedido.divisa_precio=value.data.divisa_precio;
      this.pedido.costo=value.data.costo;
      this.pedido.precio=value.data.precio;
      this.pedido.url=value.data.url;
      let fecha_estatus = new Date(value.data.fecha_estatus);
      this.pedido.fecha_estatus = new Intl.DateTimeFormat('es-MX', { dateStyle: 'short', timeStyle: 'medium'}).format(fecha_estatus);
      let fecha_estatus_c = new Date(value.data.fecha_estatus_c);
      this.pedido.fecha_estatus_c = new Intl.DateTimeFormat('es-MX', { dateStyle: 'short', timeStyle: 'medium'}).format(fecha_estatus_c);
      this.pedido.notas = value.data.notas;  
      this.pedido.cliente_id=value.data.cliente_id;
      this.pedido.empresa=value.data.empresa;
      this.pedido.cliente_contacto=value.data.cliente_contacto;
      this.pedido.cliente_email=value.data.cliente_email;
      this.pedido.website_id=value.data.website_id;
      this.pedido.website_sitio=value.data.website_sitio;
      this.pedido.website_contacto=value.data.website_contacto;
      this.pedido.website_email=value.data.website_email;
      this.pedido.datos_de_pago=value.data.datos_de_pago;
      this.pedido.estatus_id=value.data.estatus_id;
      this.pedido.estatus_nombre=value.data.estatus_nombre;
      this.pedido.estatus_color=value.data.estatus_color;
      this.pedido.estatus_c_id=value.data.estatus_c_id;
      this.pedido.estatus_c_nombre=value.data.estatus_c_nombre;     
      this.pedido.estatus_c_color=value.data.estatus_c_color;
    },
    cancelarEditar(){
      this.cleanTextBox();
      this.hideChildModal();
      this.errors=[];
    },
    cancelarShow(){
      this.cleanTextBox();
      this.hideChildModalShow();
      this.errors=[];
    },
    onHidden(e){
      this.cancelarEditar();
    },
    onHiddenShow(e){
      this.cancelarShow();
    },
    actualizarPedido(){
      console.log(this.pedido);
        axios.post('pedidos-update-admin/'+this.pedido_id, this.pedido).then(response => {
            if(response && response.data.statusCode == 200){
                this.messageAlert = response.data.message;
                this.cleanTextBox();
                this.hideChildModal();
                this.getList();
		 	}
		 	else{
		 		console.log(response);
		 	}
		}).catch((error)=> {
		    if (error.response.status == 422){
			    this.errors = error.response.data.errors;
		 	}
             else{
		 			console.log(this.errors);
			}
		}); 
    },
    getList(){
      let hoy = moment().tz('America/Mexico_City');
      const options = { style: 'currency', currency: 'MXN' };
      const numberFormat = new Intl.NumberFormat('es-MX', options);
	    axios.get('listar-pedidos-admin').then(response=>{
        let pedidos = response.data.pedidos;
        pedidos.map(function(dato){
          let fecha_estatus = moment(dato.fecha_estatus);
          let horas = hoy.diff(fecha_estatus, 'hours');
          if(dato.estatus_id == 3 && horas > 24){
            dato.estatus_id = 4;
            dato.estatus_nombre = "Por pagar";
            dato.estatus_color = "#0000FF";
          }
          dato.costo = numberFormat.format( dato.costo);
          dato.precio = numberFormat.format( dato.precio);
          dato.pedido = 'P'+dato.pedido;
          return dato;
        });
      this.pedidos = pedidos;
      this.websites = response.data.websites;
      this.clientes = response.data.clientes;
      this.estatus = response.data.estatus;
      this.estatus_c = response.data.estatus_c;
      })
    },
  },
  mounted(){
    this.getList();
  },
}
</script>
<style scoped>
  .small {
    font-size: smaller;
  }
  .error_message{
    color:#FF6868;
    font-weight:normal !important;
  }
  .active_password{
      font-size: smaller;
      color: #33FFA9;
  }
  .desactive_password{
    font-size: smaller;
    color: #C4C4C4;
    transition: all 0.3s;
  }
  .btn-custom {
    display: inline-block;
    color: #fff;
    letter-spacing: 0;
    border-radius: 15px;
    padding: 2px 18px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    text-decoration: none;
  }
</style>
