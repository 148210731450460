//require('./bootstrap');

import { createApp } from 'vue';

import LoginComponent from './components/auth/LoginComponent';
import HomeComponent from './components/HomeComponent';
import UserComponent from './components/UserComponent';
import ClientesComponent from './components/ClientesComponent';
import WebsitesComponent from './components/WebsitesComponent';
import PedidosComponent from './components/PedidosComponent';
import PedidosAdministradorComponent from './components/PedidosAdministradorComponent';
import PropietariosComponent from './components/PropietariosComponent';

const app = createApp({
    components: {
        LoginComponent,
        HomeComponent,
        UserComponent,
        ClientesComponent,
        WebsitesComponent,
        PedidosComponent,
        PedidosAdministradorComponent,
        PropietariosComponent,
    }
});

app.mount('#app');